
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Segoe UI, SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent; /*for removing the blue color layer when clicked on mobile devices*/
      word-break: break-word;
  }
  .header {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: block;
    align-items: center;
    justify-content: space-between;
    height: var(--header-height);
  }

.nam{

    font-size: 30px;
    color: black;
}
.linkStyle {
    color: rgb(6, 6, 6);
    margin-left: 90px;
}
.haju{
  display: block;
  margin-top: 0px;
  float: right;
}
/* .img{
 /* background-image: url(./../../Logo.jpg); */
  /* width: 100px;
  height: 130px;

} */ 
@media only screen and ( max-width: 840px) {
  * {
    box-sizing: border-box;
    font-family: Segoe UI, SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent; /*for removing the blue color layer when clicked on mobile devices*/
      word-break: break-word;
  }
  .header {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--header-height);
  }

  .nam{
      margin-left: 10px;
      font-size: 20px;
      color: black;
  }
  .linkStyle {
      color: rgb(6, 6, 6);
      margin-left: 10px;
      border-bottom: 1px solid rgb(29, 27, 27);
  }
  .haju{
    margin-top: 10px;
    margin-left: 0px;
    float: left;
  }
}