body{
  background-image: url('backk4.jpg');
}
.orj{
  font-weight: bold;
  display: block;
  font-size: 1.17em;
  margin-block-start: 0px;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
@media only screen and (max-width: 600px) {
  body {
    background-image: url('backk4.jpg');
  }
  .orj{
    font-weight: bold;
    display: block;
    font-size: 1.17em;
    margin-block-start: 0px;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
}