body{
    color: rgb(103, 98, 98);
    padding: 40px;
    font-family: Arial;
    text-align: left;
  }
  .leg{
    margin-top: 15px;
    color: black;
    font-size: 20px;
  }
  .sename{
    width: 20%;
    padding: 10px 0;
    font-size: 15px;
    color: rgb(67, 66, 66);
    margin-top: 20px;
    border: none;
    border-bottom: 1px solid rgb(29, 27, 27);
    outline: none;
    background: white;

  }
  .selectb{
    width: 20%;
    margin-top: 20px;
    color: rgb(67, 66, 66);
    min-height: 40px;
    max-height: 300px;
    font-size: 15px;
    border-radius: 5px;
    background: whitesmoke;
  }
  .Submit{
  width: 20%; 
  height: 40px;
  background-color: rgb(0, 71, 203);
  font-size: 15px;
  margin-top: 10px;
  padding: 5px;
  border-radius: 100px;
  color: rgb(255, 255, 255);
  border: 0px;
  }
  .dans{
    color: black;
    padding: 10px 0;
    font-size: 15px;
  }
  .register{
    width: 20%;
    padding: 10px 0;
    font-size: 15px;
    color: rgb(67, 66, 66);
    margin-top: 20px;
    margin-bottom: 20px;
    border: none;
    border-bottom: 1px solid rgb(29, 27, 27);
    outline: none;
    background: white;
  }
  .img{
    width: 222px;
    height: 305px;
    border-radius: 25px;
    background-image: url('qpay_50_1_50.png');
  }
  .anhaar{
    margin-top: 10px;
    text-align: center;
    color: red;
    background-color:white;
    width: 41%;
    height: 90px;
    float: right;
  }

  .orj{
    font-weight: bold;
    display: block;
    font-size: 1.17em;
    margin-block-start: 0px;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .lastname{
    width: 20%;
    padding: 10px 0;
    font-size: 15px;
    color: rgb(67, 66, 66);
    margin-top: 20px;
    border: none;
    border-bottom: 1px solid rgb(29, 27, 27);
    outline: none;
    background: white;
  }
  @media only screen and (max-width: 640px) {
    body{
      color: rgb(103, 98, 98);
      padding: 40px;
      font-family: Arial;
      text-align: left;
    }
    .leg{
      margin-top: 15px;
      color: black;
      font-size: 20px;
    }
    .sename{
      width: 85%;
      padding: 10px 0;
      font-size: 15px;
      color: rgb(67, 66, 66);
      margin-top: 20px;
      border: none;
      border-bottom: 1px solid rgb(29, 27, 27);
      outline: none;
      background: white;
  
    }
    .selectb{
      width: 85%;
      margin-top: 20px;
      color: rgb(67, 66, 66);
      min-height: 40px;
      max-height: 300px;
      font-size: 15px;
      border-radius: 5px;
      background: whitesmoke;
    }
    .Submit{
    width: 85%; 
    height: 40px;
    background-color: rgb(0, 71, 203);
    font-size: 15px;
    margin-top: 10px;
    padding: 5px;
    border-radius: 100px;
    color: rgb(255, 255, 255);
    border: 0px;
    }
    .dans{
      color: black;
      padding: 10px 0;
      font-size: 15px;
    }
    .register{
      width: 85%;
      padding: 10px 0;
      font-size: 15px;
      color: rgb(67, 66, 66);
      margin-top: 20px;
      margin-bottom: 20px;
      border: none;
      border-bottom: 1px solid rgb(29, 27, 27);
      outline: none;
      background: white;
    }
    .img{
      width: 222px;
      height: 305px;
      border-radius: 25px;
      background-image: url('qpay_50_1_50.png');
    }
    .anhaar{
      width: 100%;
      height: 100px;
      margin-top: 10px;
      text-align: center;
      color: red;
      font-size: bold;
      background-color:white;
      display: block;
      box-sizing: border-box;
      float: center;
    }
  
    .orj{
      font-weight: bold;
      display: block;
      font-size: 1.17em;
      margin-block-start: 0px;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
    .lastname{
      width: 85%;
      padding: 10px 0;
      font-size: 15px;
      color: rgb(67, 66, 66);
      margin-top: 40px;
      border: none;
      border-bottom: 1px solid rgb(29, 27, 27);
      outline: none;
      background: white;
    }
  }